import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, createRef } from 'react';
import { Stack, Typography } from '@mui/material';
import PaletteComponent, { usePaletteComponent } from "./PaletteComponent";
import { fetchRecords } from '../actions/fetchRecords';
type ColorSelectorProps = {
    setButtonSaveProperties?:any;
    imagesToColorsArray: any;
    zone: string;
    centered?:boolean;
}
const ColorSelector = ({  setButtonSaveProperties, imagesToColorsArray, zone, centered }:ColorSelectorProps) => {
    const theme = useTheme();
    const [ arrayRef, setArrayRef ] = useState([]);
    const { showPaletteComponent, handlePaletteComponent, mode, setMode } = usePaletteComponent();
    const [ userPalettes, setUserPalettes ] = useState<any>( [] );
    
    useEffect(() => {

        const arr:any = [];

        imagesToColorsArray.map( ( v:any, i:number ) => (
            arr[i] = createRef()
        ) );

        setArrayRef( arr );

        getPalettes().then()
    },[ ]);

    const getPalettes = async () => {
        const { palettes } = await fetchRecords( `palettes/byZone/${ zone }` );
        setUserPalettes( palettes )

    }

    return(
        <Stack
            spacing={ 2 }
        >
            {
                imagesToColorsArray && imagesToColorsArray.map( (image:any, index:number) => (
                    
                    <PaletteComponent
                        setButtonSaveProperties={ setButtonSaveProperties }
                        mode={ mode }
                        setMode={ setMode }
                        arrayRef={ arrayRef }
                        imageToProcess={ image }
                        getPalettes={ getPalettes }
                        key={ index }
                        enableDelete={ false }
                        handlePaletteComponent={ handlePaletteComponent }
                        centered={ centered }
                    />
           
                    
                ) )
            }
        </Stack>
    )
}

export default ColorSelector;
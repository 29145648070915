import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import './TypingAnimation.css';


enum Phase {
    Typing,
    Pausing,
    Deleting,
}

const __TYPING_INTERVAL__ = 150;
const __PAUSE_INTERVAL = 1000;
const __DELETING_INTERVAL = 50;

export const useTypyingAnimation = (texts: any) => {
    const [phase, setPhase] = React.useState( Phase.Typing );
    const [currentWord, setCurrentWord] = React.useState('');
    const [selectedIndexWord, setSelectedIndexWord ] = React.useState<number>( 0 );

    React.useEffect(() => {
        switch( phase ) {
            case Phase.Typing: {
                const nextWord = texts[selectedIndexWord].text.slice(0, currentWord.length + 1);

                if( nextWord === currentWord ) {
                    setPhase(Phase.Pausing);
                    return;
                };

                const timeout = setTimeout(() => {
                    setCurrentWord(nextWord);
                }, __TYPING_INTERVAL__ )
                return () => clearTimeout( timeout );
            }
            case Phase.Pausing: {

                const timeout = setTimeout(() => {
                    setPhase(Phase.Deleting)
                }, __PAUSE_INTERVAL )
                return () => clearTimeout( timeout );
            }
            case Phase.Deleting: {

                if( !currentWord ) {
                    const nextIndex = selectedIndexWord + 1;
                    setSelectedIndexWord(
                        texts[ nextIndex ] ? nextIndex : 0
                    )

                    setPhase(Phase.Typing);
                    return;
                };
                const nextRemaining = texts[selectedIndexWord].text.slice(0, currentWord.length - 1);
                        
                const timeout = setTimeout(() => {
                    setCurrentWord( nextRemaining )
                }, __DELETING_INTERVAL);
            }
        }

    },[texts, currentWord, selectedIndexWord, phase ]);

    return currentWord
}

export const TypingAnimation = () => {
    const theme = useTheme();
    const currentWord = useTypyingAnimation([{
        text: 'Emprendedores',
        duration: 2000,
    },{
        text: 'Influencers',
        duration: 5000
    },{
        text: 'Profesionales',
        duration: 5000
    },{
        text: 'Restaurantes',
        duration: 5000
    },{
        text: 'Empresas',
        duration: 5000
    },{
        text: 'Creadores de contenido',
        duration: 5000
    }
    ]);

    return(
        <Typography 
        sx={{
            color: theme.palette.secondary.main
        }}
        variant="h5" className="blinking">
           { currentWord }
        </Typography>
    )
}
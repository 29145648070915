import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Logo from '../images/logo.svg';
import LogoDark from '../images/logo-dark.svg';
import CardComponent from './CardComponent';

const MainContentPreview = () => {
    const theme = useTheme();
    return(
        <Paper
        sx={{
            mt: {
                xs: 40,
            },
            backgroundColor: theme.palette.background.paper,
            height: '100%',
            width: 306,
            position: 'relative',
            borderRadius: '20px 20px 0 0'
        }}
        
    >
        <Container sx={{ py: 1 }} maxWidth="md">
        
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography sx={{ mb: 1, mt: 2 }} variant="caption"> 
                    Categoría de ejemplo
                </Typography>          
            </Box>

            <CardComponent data={ {
                title: 'Link de ejemplo',
                description: 'Descripción de tu link',
                buttonText: 'Texto del botón'
            } }/>
                
            <Box
                sx={{
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'center'
                }} 
            >
                <img
                    style={{
                        cursor: 'pointer',
                        marginBottom: 8
                    }}
                    width={ 60 }
                    src={ theme?.palette?.mode === 'dark' ? Logo : LogoDark }
                    alt="logo-oh-my-zone"
                />
            </Box>
        </Container>
    </Paper>
    )
}

export default MainContentPreview;
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, CircularProgress, useTheme, Divider, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';

import { transition } from '../constants/transitions';

// @ts-ignore
import Background from '../images/premium-background.svg';

import { Controller, useForm } from 'react-hook-form';
import { postRecord } from '../actions/postRecord';
import { motion } from 'framer-motion';
import { red } from '@mui/material/colors';
import StyledButton from '../styled/StyledButton';
import { GoogleButton, useGoogleButton } from './common/GoogleButton';
import { useAppSelector } from '../app/hooks';
import { updateRecord } from '../actions/updateRecord';
import { dataURItoBlob } from './UploadFile';
import { navigate } from 'gatsby';
type ModalSignUpType = {
    handleModal: any;
    openModal: any;
    loading?: boolean;
    setLoading?: any;
    onSubmit?: any;
    handleSubmit:any,
    setValue:any,
    control:any,
    errors:any,
    setError:any,
    signIn:any,
    loadingGoogle:any,
    setLoadingGoogle:any
}

export const useModalSignUp = ( ) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [ modalTitle, setModalTitle ] = useState<string>(' ');
    const [ loading, setLoading ] = useState<boolean>(false);
    const state:any = useAppSelector( (state:any) => state );
    const { handleSubmit, setValue, control, formState: { errors }, setError } = useForm();
    const { signIn, loadingGoogle, setLoadingGoogle } = useGoogleButton();

    useEffect(() => {

        if( state.auth?.isNewUser  ) {
            if( state.auth.isNewUser === true  ) {

                handleProcessSignUp( state.auth.uid ).then(() => {
                    //Redirects to dashboard
                    window.open( `${ process.env.GATSBY_APP_HOST }dashboard/${ state?.auth?.token }`, '_blank' );
                })

            }else {
                window.open( `${ process.env.GATSBY_APP_HOST }dashboard/${ state?.auth?.token }`, '_blank' );

            }
        }
    },[ state.auth ]);

    const handleModal = () => setOpenModal(!openModal);

    const prepareImageToUpload = (zone:string, folder:string, fileType:string, fileName:string, dataUri:string) => {

        const formData = new FormData();
        formData.append( 'zone', zone );

        formData.append( 'folderName', folder );

        let dataBlob:Blob = new Blob();
        let metadata = {
            type: fileType
        };

        dataBlob = dataURItoBlob( dataUri )
        const blob = new File([ dataBlob ], fileName, metadata);
        formData.append('file', blob);

        return formData;
    }

    const handleProcessSignUp = async ( userUid:string ) => {
        const { zone } = await postRecord('zones', {
            title: state.zone.title,
            username: state.zone.username,
            user: userUid,
            currentStatus: 'isCompleted',
            socialLinks: state.zone?.socialLinks
        });

        //Upload image profile
        if( state?.zone?.dataImage ) {

            const imageToUpload = prepareImageToUpload(
                zone.uid,
                `${zone.username}/profile`,
                state.zone.dataImage.type,
                state.zone.dataImage.name,
                state.zone.dataImage.dataImage
            );

            const { image } = await postRecord('images', imageToUpload);

            if(image) {
                await updateRecord('zones', {
                    profileImage: image.uid
                }, zone.uid );
            }
        }

        let imageTheme:any = null;

        if( state?.theme?.dataImage ) {
            const imageToUpload = prepareImageToUpload(
                zone.uid,
                `${zone.username}/themes`,
                state.theme.dataImage.type,
                state.theme.dataImage.name,
                state.theme.dataImage.dataImage
            );

            imageTheme = await postRecord('images', imageToUpload);
        }

        //Saves palette
        const { palette } = await postRecord( 'palettes', {
            ...state.theme,
            backgroundImageUrl: null,
            dataImage: null,
            zone: zone.uid
        } );

        const { theme } = await postRecord( 'themes', {
            palette: palette.uid,
            mode: state?.theme?.mode,
            backgroundImage: imageTheme?.image ? imageTheme.image.uid : null,
            zone: zone.uid,
            user: userUid
        } );

        await updateRecord('zones', {
            theme: theme.uid
        }, zone.uid );

        return true;
    };

    const onSubmit = async (data: any) => {
        setLoading( true );
        const result = await postRecord('users', {
            ...data,
            role: 'USER'
        });


        if( result?.success === false ) {
            setLoading( false );
            setError( result.error.response.data.errors[0].param, {
                type: 'manual',
                message: result.error.response.data.errors[0].msg
            } )
            return;
        }

        await handleProcessSignUp( result.user.uid );

        const resultLogin = await postRecord( 'auth/login', {
            email: data.email,
            password: data.password
        } );

        setLoading( false );

        //Redirects to dashboard
        window.open( `${ process.env.GATSBY_APP_HOST }dashboard/${ resultLogin.token }`, '_blank' );
    };

    return {
        openModal,
        handleModal,
        modalTitle,
        setModalTitle,
        loading,
        setLoading,
        control,
        errors,
        setError,
        setValue,
        handleSubmit,
        onSubmit,
        signIn,
        loadingGoogle,
        setLoadingGoogle
    }
}

export const ModalSignUp = ({ handleModal, openModal, onSubmit, loading, setLoading, handleSubmit, setValue, control,   errors ,  signIn,
                                loadingGoogle,
                                setLoadingGoogle }: ModalSignUpType) => {

    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.up('md'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mediaQuery ? 480 : 350,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,

    };
    return (
        <Modal
            sx={{
                overflowY: 'scroll'
            }}
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: 200,
                        backgroundColor: '#4664F6',
                        pt: 2,
                        overflow: 'hidden'
                    }}
                >
                    <motion.img
                        initial={{
                            height: 200,
                            y: 50
                        }}
                        animate={{
                            y: 5,
                            height: 200,
                            transition: { ...transition }
                        }}
                        alt="logo"
                        src={Background}
                        style={{
                            height: 120,
                            position: 'absolute',
                            zIndex: 0
                        }}
                    />
                </Box>

                <Box sx={{ p: 3, pb: 2 }}>
                    <Typography
                        variant="h4"
                        align="center"
                        sx={{
                            color: theme.palette.text.primary
                        }}
                    >
                        ¡Sólo un paso más!
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.secondary
                        }}
                        align="center"
                        variant="body2"
                    >
                        ¡Regístrate para continuar editando tu Zone!
                    </Typography>
                    <form onSubmit={ handleSubmit( onSubmit ) }>
                        <Grid mt={1} mb={2} spacing={2} container>
                            <Grid xs={12} md={ 6 } item>
                                <Controller
                                    name="name"
                                    control={ control }
                                    rules={{
                                        required: 'Ingresa tu nombre',
                                    }}
                                    render={ ({ field: { value, onChange } }) => (
                                        <TextField
                                            onChange={ onChange }
                                            fullWidth
                                            value={ value }
                                            label="Tu nombre"
                                        />
                                    ) }
                                />
                                { errors.name && <Typography variant="caption" sx={{color: red[200]}}>{errors.name.message}</Typography> }
                            </Grid>
                            <Grid xs={12} md={ 6 } item>
                                <Controller
                                    name="email"
                                    control={ control }
                                    rules={{
                                        required: 'Ingresa tu email',
                                    }}
                                    render={ ({ field: { value, onChange } }) => (
                                        <TextField
                                            InputProps={{
                                                type: "email"
                                            }}
                                            onChange={ onChange }
                                            fullWidth
                                            value={ value }
                                            label="Tu email"
                                        />
                                    ) }
                                />
                                { errors.email && <Typography variant="caption" sx={{color: red[200]}}>{errors.email.message}</Typography> }
                            </Grid>
                            <Grid xs={12} item>
                                <Controller
                                    name={ "password" }
                                    control={ control }
                                    rules={{
                                        required: 'La contraseña es requerida.',
                                        minLength: {
                                            value: 6,
                                            message: 'La contraseña debe tener al menos 6 carácteres.'
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            InputProps={{
                                                type: "password"
                                            }}
                                            fullWidth
                                            onChange={onChange}
                                            value={value}
                                            label="Ingresa una contraseña"
                                        />
                                    )}
                                />
                                {errors.password && <Typography variant="caption" sx={{color:'red'}}>{ errors.password.message }</Typography>}
                            </Grid>
                            <Grid item xs={ 12 }>
                                <StyledButton
                                    id="sign-up-button"
                                    disabled={ loading }
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    type="submit"
                                    startIcon={ loading && <CircularProgress size={ 12 }/> }
                                >
                                    ¡Registrarme gratis!
                                </StyledButton>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Divider sx={{
                                    mb:1,
                                    fontFamily: 'roboto',
                                    "&.MuiDivider-root": {
                                        "&::before": {
                                            borderTop: "thin solid #AAAFB6"
                                        },
                                        "&::after": {
                                            borderTop: "thin solid #AAAFB6"
                                        }
                                    },
                                    "& .MuiDivider-wrapper": {
                                        fontSize: 12,
                                        color: '#AAAFB6'
                                    }
                                }}>
                                    O bien
                                </Divider>
                                <Grid item xs={12}>
                                    <GoogleButton signIn={ signIn } loadingGoogle={ loadingGoogle } setLoadingGoogle={ setLoadingGoogle } />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ textAlign: 'center' }} color="text.secondary" variant="caption">
                                    <Typography color="text.secondary" variant="caption" >
                                        Al registrarte aceptas nuestro
                                    </Typography>{' '}
                                    <Typography color="text.secondary" variant="caption" onClick={() => navigate('/legal/privacy')} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                        Aviso de privacidad
                                    </Typography>{' '}
                                    <Typography color="text.secondary" variant="caption">
                                        y
                                    </Typography>{' '}
                                    <Typography color="text.secondary" variant="caption" onClick={() => navigate('/legal/terms')} sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                        Términos y condiciones
                                    </Typography>
                                </Typography>

                            </Grid>
                            <Grid item xs={ 12 }>
                                <StyledButton
                                    onClick={ handleModal }
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                >
                                    Cerrar
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </form>

                </Box>


            </Box>
        </Modal>
    )
}
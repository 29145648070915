import * as React from 'react';
import StyledSwitch from '../../styled/StyledSwitch';
import TextField from '@mui/material/TextField';
import { InputAdornment, Grid, Stack, useTheme, Typography } from '@mui/material';
import {Call, Email, Facebook, Instagram, WhatsApp, YouTube} from '@mui/icons-material';
import { ContactOptionsType } from '../../types/ContactOptionsType';
import { Tiktok } from '@styled-icons/boxicons-logos/Tiktok';
import { motion } from "framer-motion";
import { transition } from '../../constants/transitions';
import { Controller, useForm } from 'react-hook-form';
import CircularProgressComponent from '../../components/CircularProgressComponent';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import FormNavigationButtons, { useFormNavigationButtons } from '../../components/FormNavigationButtons';
import { updateZone } from '../../actions/zones';

const defaultSocialIcons = {
    facebook: null,
    instagram: null,
    whatsapp: null,
    tiktok: null,
    phone: null,
    email: null,
    youtube: null,
}

const SocialIconsSection = ( {prev, next, setStep}:{ prev?:number, next?:number, setStep:any } ) => {
    const { zone } = useSelector( (state:any) => state );
    const theme = useTheme();

    const [ loading, setLoading ] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const { control, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm();
    const { buttonSaveProperties, setButtonSaveProperties } = useFormNavigationButtons();
    const [ isFormReady, setIsFormReady ] = React.useState<boolean>( false );

    const [ contactOptions, setContactOptions ] = React.useState<ContactOptionsType>(defaultSocialIcons);

    React.useEffect(() => {
        setDefaultValues(zone);
        setIsFormReady(true);
        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Continuar',
            isVisible: true,
        }));
    },[ ])

    const setDefaultValues = (zone:any) => {

        if( zone?.socialLinks ) {
            setContactOptions( zone?.socialLinks );
        }

        if( zone?.socialLinksCounters ) {
            setContactOptions( zone?.socialLinks );
        }

        zone?.socialLinks?.instagram && setValue( 'instagram', zone?.socialLinks?.instagram );
        zone?.socialLinks?.email && setValue( 'email', zone?.socialLinks?.email );
        zone?.socialLinks?.whatsapp && setValue( 'whatsapp', zone?.socialLinks?.whatsapp );
        zone?.socialLinks?.tiktok && setValue( 'tiktok', zone?.socialLinks?.tiktok );
        zone?.socialLinks?.phone && setValue( 'phone', zone.socialLinks.phone );
        zone?.socialLinks?.facebook && setValue( 'facebook', zone?.socialLinks?.facebook );
        zone?.socialLinks?.youtube && setValue( 'youtube', zone?.socialLinks?.youtube );
    }

    const onSubmit = async (  ) => {
        setLoading( true );
    
        if(zone) {
            setLoading( false );
            setStep( next );
        }
    }

    return(
        <>
        <motion.div
            initial={{
                x: 20,
                opacity: 0

            }}
            animate={{
                x: 0,
                opacity: 1,
                transition: { ...transition }
            }}
        >
            <Typography sx={{ mt: 2, mb: 1, color: theme.palette.text.secondary }} variant="subtitle1">Botones de contacto</Typography>
        </motion.div>
        {
            isFormReady ? 
            (
                <form onSubmit={ handleSubmit( onSubmit )}>
                    <Grid spacing={ 2 }  container>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, facebook: val});

                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                facebook: val
                                            }
                                        }));

                                        
                                        clearErrors('facebook');
                                    } }
                                    checked={ contactOptions?.facebook !== null } 
                                />
                                <Controller
                                    name="facebook"
                                    control={ control }
                                    rules={{
                                        required: contactOptions?.facebook !== null ? 'Ingresa tu nombre de usuario de Facebook' : false
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            disabled={ contactOptions.facebook === null }
                                            placeholder="Nombre de usuario de tu Facebook"
                                            fullWidth
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        facebook: e.target.value
                                                    }
                                                }));
                                            } }
                                            onChange={ onChange }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Facebook color={ contactOptions.facebook ? "primary" : "disabled" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            { errors.facebook && <Typography variant="caption" sx={{color:'red'}}>{errors.facebook.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, instagram: val })
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                instagram: val
                                            }
                                        }));

                                        clearErrors('instagram');
                                    } }
                                    checked={ contactOptions?.instagram !== null  } 
                                />
                                <Controller
                                    name="instagram"
                                    rules={{
                                        required: contactOptions?.instagram !== null ? 'Ingresa tu nombre de usuario de Instagram' : false
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (
                                        
                                        <TextField
                                            disabled={ contactOptions.instagram === null }
                                            placeholder="Nombre de usuario de tu instagram"
                                            fullWidth
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        instagram: e.target.value
                                                    }
                                                }));
                                            }}
                                            onChange={ onChange }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Instagram color={ contactOptions.instagram ? "primary" : "disabled" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            { errors.instagram && <Typography variant="caption" sx={{color:'red'}}>{errors.instagram.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, phone: val});
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                phone: val
                                            }
                                        }));
                                        clearErrors('phone');
                                    } }
                                    checked={ contactOptions?.phone !== null } 
                                />
                                <Controller
                                    name="phone"
                                    rules={{
                                        required: contactOptions.phone !== null ? 'Ingresa tu teléfono' : false
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (
                                        
                                        <TextField
                                            disabled={ contactOptions.phone === null }
                                            placeholder="Tu teléfono"
                                            fullWidth
                                            onChange={ onChange }
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        phone: e.target.value
                                                    }
                                                }));
                                            } }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Call color={ contactOptions.phone ? "primary" : "disabled" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                
                            </Stack>
                            { errors.phone && <Typography variant="caption" sx={{color:'red'}}>{errors.phone.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null
                                        setContactOptions({ ...contactOptions, whatsapp: val});
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                whatsapp: val
                                            }
                                        }));
                                        clearErrors('whatsapp');
                                    } }
                                    checked={ contactOptions.whatsapp !== null } 
                                />
                                <Controller
                                    name="whatsapp"
                                    rules={{
                                        required: contactOptions?.whatsapp !== null ? 'Ingresa tu WhatsApp' : false
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (
                                        
                                        <TextField
                                            disabled={ contactOptions.whatsapp === null }
                                            placeholder="Tu WhatsApp"
                                            fullWidth
                                            onChange={ onChange }
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        whatsapp: e.target.value
                                                    },
                                                    socialLinksCounter: {

                                                    }
                                                }));
                                            } }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <WhatsApp color={ contactOptions.whatsapp ? "primary" : "disabled" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                
                            </Stack>
                            { errors.whatsapp && <Typography variant="caption" sx={{color:'red'}}>{errors.whatsapp.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, email: val})
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                email: val
                                            }
                                        }));

                                        clearErrors('email');
                                    } }
                                    checked={ contactOptions.email !== null } 
                                />
                                <Controller
                                    name="email"
                                    rules={{
                                        required: contactOptions.email !== null ? 'Ingresa tu Email' : false,
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: 'El correo no es válido.'
                                        }
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (
                                        
                                        <TextField
                                            disabled={ contactOptions.email === null }
                                            placeholder="Tu Email"
                                            fullWidth
                                            onChange={ onChange }
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        email: e.target.value
                                                    }
                                                }));
                                            } }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email color={ contactOptions.email ? "primary" : "disabled" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                
                            </Stack>
                            { errors.email && <Typography variant="caption" sx={{color:'red'}}>{errors.email.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch 
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, tiktok: val})
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                tiktok: val
                                            }
                                        }));

                                        clearErrors('tiktok');
                                    } }
                                    checked={ contactOptions.tiktok !== null } 
                                />
                                <Controller
                                    name="tiktok"
                                    rules={{
                                        required: contactOptions.tiktok !== null ? 'Ingresa tu usuario de Tiktok' : false
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (
                                        
                                        <TextField
                                            disabled={ contactOptions.tiktok === null }
                                            placeholder="Nombre de usuario de tu Tiktok"
                                            fullWidth
                                            onChange={ onChange }
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        tiktok: e.target.value
                                                    }
                                                }));
                                            } }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Tiktok size={24} color={ contactOptions.tiktok ? theme.palette.primary.main : "rgba(255,255,255,0.3)" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                                
                            </Stack>
                            { errors.tiktok && <Typography variant="caption" sx={{color:'red'}}>{errors.tiktok.message }</Typography>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between' }}>
                                <StyledSwitch
                                    onChange={ (e:React.ChangeEvent<HTMLInputElement>) => {
                                        let val:any = e.target.checked ? '' : null;
                                        setContactOptions({ ...contactOptions, youtube: val})
                                        dispatch( updateZone({
                                            ...zone,
                                            socialLinks: {
                                                ...contactOptions,
                                                youtube: val
                                            }
                                        }));

                                        clearErrors('youtube');
                                    } }
                                    checked={ contactOptions.youtube !== null }
                                />
                                <Controller
                                    name="youtube"
                                    rules={{
                                        required: contactOptions.youtube !== null ? 'Ingresa tu usuario de YouTube' : false
                                    }}
                                    control={ control }
                                    render={ ({ field: { onChange, value } }) => (

                                        <TextField
                                            disabled={ contactOptions.youtube === null }
                                            placeholder="Nombre de usuario de tu YouTube"
                                            fullWidth
                                            onChange={ onChange }
                                            onBlur={ (e) => {
                                                dispatch( updateZone({
                                                    ...zone,
                                                    socialLinks: {
                                                        ...contactOptions,
                                                        youtube: e.target.value
                                                    }
                                                }));
                                            } }
                                            value={ value }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        { /* @ts-ignore */ }
                                                        <YouTube size={24} color={ contactOptions.youtube ? theme.palette.primary.main : "rgba(255,255,255,0.3)" }/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            { errors.youtube && <Typography variant="caption" sx={{color:'red'}}>{errors.youtube.message }</Typography>}
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 8, mb: 4 }}>
                        <FormNavigationButtons
                            buttonSaveProperties={buttonSaveProperties}
                            prev={ () => setStep( prev ) }
                        />
                    </Box>
                </form>
            ) : (
                <CircularProgressComponent/>
            )
                        
        }
        
        </>
    )
}

export default SocialIconsSection;
import * as React from 'react';
import { Box } from "@mui/system";
import useTheme from '@mui/material/styles/useTheme';
import { ColorPaletteImage } from "./ColorPaletteImage";
import { CurrentPaletteType } from '../types/CurrentPaletteType';
import { CardActionArea, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import { updateTheme } from '../actions/themes';
import {  useForm } from 'react-hook-form';
import PaletteColorsPreview from './common/PaletteColorsPreview';
import { transition } from '../constants/transitions';
import { motion } from 'framer-motion';

export const usePaletteComponent = () => {
    const [ mode, setMode ] = React.useState<'dark' | 'light'>( 'dark' );
    const [ showPaletteComponent, setShowPaletteComponent ] = React.useState<boolean>( false );
    const { theme } = useSelector( (state:any) => state );
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch( updateTheme({
            ...theme,
            mode
        }));
    },[ mode ]);

    const handlePaletteComponent = () => {
        setShowPaletteComponent( !showPaletteComponent )
    }
    return {
        mode,
        setMode,
        showPaletteComponent,
        handlePaletteComponent
    }
}

type PaleteComponentType = {
    mode: 'dark' | 'light',
    setMode: any,
    arrayRef?:any,
    imageToProcess?:any,
    key:number,
    getPalettes:any,
    edit?:boolean,
    defaultPalette?:any,
    enableDelete:boolean,
    handlePaletteComponent:any,
    setNewPalette?:any,
    centered?:boolean,
    setButtonSaveProperties?:any,
}

const PaletteComponent = ({setButtonSaveProperties, arrayRef, imageToProcess, key, edit, defaultPalette, mode, setMode, getPalettes, enableDelete, handlePaletteComponent, centered }:PaleteComponentType) => {
    const state = useSelector( (state:any) => state );



    const [ editMode, setEditMode ] = React.useState<boolean>( edit ? edit : false );
    const [ breathingAnimation, setBreathingAnimation ] = React.useState<boolean>( true );
    const { handleSubmit, control, setValue, formState: {errors}, reset } = useForm();
    const theme = useTheme();

    const dispatch = useDispatch();


    const [ editingPalette, setEditingPalette ] = React.useState<CurrentPaletteType>( defaultPalette ? defaultPalette : {
        vibrant: '#4664F6',
        lightVibrant: '#F8FAFF',
        darkVibrant: '#010413',
        muted: '#7AE7C7',
        lightMuted: '#CBF6E9',
        darkMuted: '#03110D'
    } );

    const [ currentPalette, setCurrentPalette ] = React.useState<CurrentPaletteType>( defaultPalette ? defaultPalette : {
        vibrant: '#4664F6',
        lightVibrant: '#F8FAFF',
        darkVibrant: '#010413',
        muted: '#7AE7C7',
        lightMuted: '#CBF6E9',
        darkMuted: '#03110D'
    } );

    React.useEffect(() => {

        if(editingPalette) {
            setValue( 'vibrant', editingPalette.vibrant.replace('#','') );
            setValue( 'lightVibrant', editingPalette.lightVibrant.replace('#','') );
            setValue( 'darkVibrant', editingPalette.darkVibrant.replace('#','') );
            setValue( 'muted', editingPalette.muted.replace('#','') );
            setValue( 'lightMuted', editingPalette.lightMuted.replace('#','') );
            setValue( 'darkMuted', editingPalette.darkMuted.replace('#','') );
            //setCurrentPalette( editingPalette );
        }


    },[ editingPalette ]);

    const handleEditMode = () => {
        handlePaletteComponent()
        setEditMode( !editMode );
    }

    const updateThemeColor = (index:any) => {
        setBreathingAnimation( false );

        setButtonSaveProperties((prev:any) => ({
            ...prev,
            isVisible: true
        }));

        if( defaultPalette ) {
            dispatch( updateTheme({
                ...state.theme,
                ...defaultPalette,
            }));
        }else {
            const reff:any = arrayRef[index];
            reff?.current.updatePalette();

            dispatch( updateTheme({
                ...state.theme,
                ...currentPalette,
            }));
        }

    }


    return(
        <>
            <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.05, 1] }}
                transition={{ ease: "linear", duration: 1.5, repeat: breathingAnimation ? 1 : 1, delay: 1 }}
            >
                <Box
                    key={ key }
                    sx={{
                        position: 'relative'
                    }}
                >
                    <motion.div
                        initial={{
                            y: 40,
                            opacity: 0,
                        }}
                        animate={{
                            y: 0,
                            opacity: 1,
                            transition: { ...transition }
                        }}
                    >
                        <Card
                            sx={{
                                borderRadius: 8
                            }}
                            elevation={ 4 }
                        >
                            <CardActionArea
                                onClick={ () => updateThemeColor( key ) }
                                sx={{
                                    p: 1
                                }}
                            >
                                <Stack direction="row">
                                    <Box sx={{
                                        flex: 1
                                    }}>
                                        {
                                            defaultPalette && (
                                                <PaletteColorsPreview data={ defaultPalette }/>
                                            )
                                        }
                                        {
                                            imageToProcess?.image && (
                                                <ColorPaletteImage setCurrentPalette={ setCurrentPalette } ref={ arrayRef[ key ] } src={ imageToProcess.image } centered={centered}/>
                                            )
                                        }
                                    </Box>

                                </Stack>
                            </CardActionArea>
                        </Card>
                    </motion.div>
                </Box>
            </motion.div>

        </>

    )
}

export default PaletteComponent;
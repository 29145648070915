
import * as React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import StyledButton from '../styled/StyledButton';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { transition } from '../constants/transitions';

export const useFormNavigationButtons = ( defaultState?:{
    color: 'primary',
    text: undefined,
    isDisabled: false,
    isLoading: false,
    isVisible: false,
} ) => {
    const [ buttonSaveProperties, setButtonSaveProperties ] = useState(defaultState);

    const handleResetButtonSave = () => {
        setButtonSaveProperties( defaultState );
    }
    return {
        handleResetButtonSave,
        buttonSaveProperties,
        setButtonSaveProperties
    }
}

const FormNavigationButtons = ({ prev, loading, next, fullForm, buttonSaveProperties }:{ prev?:any, loading?: boolean, next?:any, fullForm?:boolean, buttonSaveProperties?:any }) => {

    return (
        <Grid sx={{ justifyContent: 'center' }} spacing={ 2 } container>
            <Grid 
                xs={ 12 }
                md={ fullForm ? 12 : 6 }
                sx={{
                    order: {
                        xs: 1,
                        md: 2
                    }
                }}
                item
            >
                {
                 ( buttonSaveProperties && buttonSaveProperties?.isVisible ) && (
                    <motion.div
                    initial={{ 
                        y: 40,
                        opacity: 0,
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: { ...transition }
                    }}
                >
                        <StyledButton
                            variant="contained"
                            fullWidth
                            type={ next ? 'button' : 'submit' }
                            disabled={ buttonSaveProperties ? buttonSaveProperties?.isLoading : loading }
                            startIcon={ buttonSaveProperties?.isLoading && <CircularProgress size={ 12 } color="inherit"/> }
                            onClick={ next ? next : undefined }
                            endIcon={ <ChevronRight/> }
                        >
                            Continuar
                        </StyledButton>
                        </motion.div>
                    )
                }

            </Grid>
            { ( prev && !fullForm ) &&
                <Grid 
                    sx={{
                        order: {
                            xs: 2,
                            md: 1
                        }
                    }}
                    xs={ 12 }
                    md={ 6 }
                    item
                >
                    
                    <StyledButton
                        fullWidth
                        variant="outlined"
                        startIcon={ <ChevronLeft/> }
                        onClick={ prev ? prev : undefined }
                    >
                        Volver
                    </StyledButton>
                </Grid>
            }
        </Grid>
    )
}

export default FormNavigationButtons;

import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UploadFile, useUploader } from '../../components/UploadFile';
import { UsernameCreator, useUsernameCreator } from '../../components/UsernameCreator';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { updateZone } from '../../actions/zones';
import { fetchRecord } from '../../actions/fetchRecord';
import CircularProgressComponent from "../../components/CircularProgressComponent";
import FormNavigationButtons from '../../components/FormNavigationButtons';
import { red } from '@mui/material/colors';
import { useFormNavigationButtons } from '../../components/FormNavigationButtons';
import { ModalSignUp, useModalSignUp } from '../ModalSignUp';

const CoverSection = ({ setStep }:{ setStep:any }) => {
    const { auth, zone } = useSelector( (state:any) => state );
    const { createdUsername, setCreatedUsername } = useUsernameCreator();

    const { buttonSaveProperties, setButtonSaveProperties } = useFormNavigationButtons();
    const dispatch = useDispatch();
    const [ isFormReady, setIsFormReady ] = React.useState<boolean>( false );
    const { handleSubmit, setValue, control, formState: { errors }, setError } = useForm();
    const { dataUri, onChange, handleDelete, imageSrc, uploadToServer, openModal, handleModal, getCropData, setCropper, temporalDataUri, setDataUri, setImageServerUid } = useUploader( true );
    const { handleModal: handleSignUpModal, ...modalSignUpProps  } = useModalSignUp();

    const [ fullName, setFullName ] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if(dataUri) {
            dispatch( updateZone({
                ...zone,
                profileImage: dataUri
            }));
        }else{
            dispatch( updateZone({
                ...zone,
                profileImage: null
            }));
        }
    },[ dataUri ]);

    React.useEffect(() => {
        if( auth?.token ) {
            handleSubmit( submitForm )
        }
    },[ auth ])

    React.useEffect(() => {
        setButtonSaveProperties((prev:any) => ({
            ...prev,
            isVisible: true,
        }));
        setDataUri( zone.profileImage );
        setImageServerUid( zone?.profileImageUid ); //This will prepare delete function in useUploader hook to delete image from cloudinary
        setCreatedUsername( zone.username );
        setIsFormReady( true );
    },[ ]);

    const validateIfZoneExists = async ( username:string ) => {
        let result:boolean = false;
        //Validate that name doesn't exists
        const { zone } = await fetchRecord( 'zones/byName', username );
        if( zone ) {
            setError('username',{
                type: 'manual',
                message: 'Ups! este nombre de usuario ya fue tomado, elige otro.'
            })
            result = true;
        }

        return result;
    }
    const handleChangeName = (e:any) => {
        setFullName(e.target.value);
    }

    const submitForm = async ( ) => {
        
        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Guardando cambios',
            isDisabled: true,
            isLoading: true
        }));

        //Validate that name doesn't exists
        if( createdUsername && await validateIfZoneExists( createdUsername ) ) {

            //Revert save button to original status
            setButtonSaveProperties((prev:any) => ({
                ...prev,
                text: 'Guardar cambios',
                isDisabled: false,
                isLoading: false
            }));
            return;
        }

        const image = await uploadToServer( `/profile` ); //Uploads image to server with the id of the Zone recently created

        dispatch( updateZone( {
            ...zone,
            title: fullName,
            username: createdUsername,
            profileImage: image?.dataImage,
            dataImage: image,
        } ) );


        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Cambios guardados correctamente',
            isDisabled: true,
            isLoading: false,
            color: 'success'
        }));

        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Guardar cambios',
            isDisabled: false,
            isLoading: false,
            isVisible: false,
            color: 'primary',
        }));

        handleSignUpModal();
    }

    return(
        <>
        { isFormReady ? (
            <>
                <ModalSignUp
                    handleModal={ handleSignUpModal }
                    handleSubmit={ modalSignUpProps.handleSubmit }
                    openModal={ modalSignUpProps.openModal }
                    onSubmit={ modalSignUpProps.onSubmit }
                    loading={ modalSignUpProps.loading }
                    setLoading={ modalSignUpProps.setLoading  }
                    setValue={ modalSignUpProps.setValue }
                    control={ modalSignUpProps.control }
                    errors={ modalSignUpProps.errors }
                    setError={ modalSignUpProps.setError }
                    signIn={ modalSignUpProps.signIn }
                    loadingGoogle={ modalSignUpProps.loadingGoogle }
                    setLoadingGoogle={ modalSignUpProps.setLoadingGoogle }
                />
                <Grid sx={{ mt: 2 }} container>
                    <Grid xs={12} item>
                    
                        <UploadFile
                            file={dataUri}
                            openModal={ openModal }
                            handleModal={ handleModal }
                            onChange={ onChange } 
                            handleDelete={ handleDelete }
                            dataUri={ dataUri }
                            imageSrc={ imageSrc }
                            getCropData={ getCropData }
                            setCropper={ setCropper }
                            temporalDataUri={ temporalDataUri }
                            useCropper={ true }
                            roundedPreview={ true }
                        />
                    </Grid>
                </Grid>
                <form onSubmit={ handleSubmit( submitForm ) }>
                    <Grid mt={1} mb={2} spacing={2} container>
                        <Grid xs={12} item>
                            <Controller
                                name="title"
                                control={ control }
                                rules={{
                                    required: 'Ingresa un título para tu zone',
                                }}
                                render={ ({ field: { value, onChange } }) => (
                                    <TextField
                                        onChange={ ( e ) =>  {
                                            onChange( e.target.value );
                                            handleChangeName( e );
                                        }}
                                        fullWidth
                                        onBlur={ (e) => {
                                            dispatch( updateZone({
                                                ...zone,
                                                title: e.target.value
                                            }) );
                                        } }
                                        value={ value }
                                        label="Título de tu Zone" 
                                    />
                                ) }
                            />
                            { errors.title && <Typography variant="caption" sx={{color: red[200]}}>{errors.title.message}</Typography> }
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: 2 }} container>
                        <Grid xs={12} item>
                            <UsernameCreator
                                control={ control }
                                fullName={ fullName } 
                                createdUsername={ createdUsername }
                                setCreatedUsername={ setCreatedUsername }
                                errors={ errors }
                                setValue={ setValue }
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 8, mb: 4 }}>
                        <FormNavigationButtons
                            prev={ () => setStep( 2 ) }
                            buttonSaveProperties={ buttonSaveProperties }
                        />
                    </Box>
                </form>
            </>
        ) : <CircularProgressComponent/> }
        </>
    )
}

export default CoverSection;
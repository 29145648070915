import * as React from 'react';
import withTheme from '../../components/WithTheme';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import useTheme from '@mui/material/styles/useTheme';
import PreviewSection from './PreviewSection';
import CoverSection from './CoverSection';
import StepperComponent from '../../components/StepperComponent';
import SocialIconsSection from './SocialIconsSection';
import ThemeSection from './ThemeSection';
import ZonePhonePreview from '../../components/ZonePhonePreview';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const ZoneForm = () => {
    const [ openDrawer, setOpenDrawer ] = React.useState<boolean>( false );
    const [ step, setStep ] = React.useState<number>( 1 );
    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.up('md'));
    const [ title,setTitle ] = React.useState<string | null>( null );

    React.useEffect(() => {
        if( step === 1) {
            setTitle( 'Colores e identidad de tu Zone' )
        }
        if( step === 2) {
            setTitle( 'Ahora tus redes sociales' )
        }
        if( step === 3) {
            setTitle( 'Ya casi, falta darle un nombre a tu Zone' )
        }
    },[ step ])

    return (
        <>
            <Paper
                sx={{
                    borderRadius: 0,
                    pb: 6
                }}
                elevation={ 0 }
            >
                <Container id="creator" sx={{ position: 'relative' }} maxWidth="lg">
                    { step === 5 ? <PreviewSection/> : (
                        <Grid sx={{ justifyContent: 'center' }} container>
                            <Grid xs={ 12 } md={ 6 } item>

                                {
                                    step !== 1 ? (
                                        <StepperComponent titleStep={ title }actualStep={ step } totalSteps={ 3 } />
                                    ) : (
                                        <>
                                            <Typography
                                                color="text.primary"
                                                variant="h5"
                                                align="center"
                                            >
                                                ¡Crea tu Zone en segundos!
                                            </Typography>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                                align="center"
                                            >
                                                Pruébalo ahora, sube o busca una imagen de fondo
                                            </Typography>
                                        </>
                                    )
                                }

                                { step === 3 && <CoverSection setStep={ setStep }/> }

                                { step === 2 && <SocialIconsSection setStep={ setStep } prev={ 1 } next={ 3 }/> }

                                { step === 1 && <ThemeSection setStep={ setStep } prev={ 3 } next={ 5 }/> }

                            </Grid>
                            {
                                mediaQuery && (
                                    <Grid md={ 6 } item>
                                        <ZonePhonePreview relative/>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )}
                    {
                        !mediaQuery && (
                            <Fab sx={{
                                textTransform: 'none',
                                position: 'fixed',
                                bottom: 0,
                                borderRadius: '20px 20px 0 0',
                                left: '50%',
                                zIndex: 101,
                                transform: 'translate(-50%, 0)',
                            }}  color="secondary"
                                 variant="extended"
                                 size="large"
                                 onClick={ () => setOpenDrawer( !openDrawer ) }
                            >
                                {
                                    !openDrawer ? (
                                        <Visibility sx={{ mr: 1 }}/>
                                    ) : (
                                        <VisibilityOff sx={{ mr: 1 }}/>
                                    )
                                }
                                Vista previa
                            </Fab>

                        )
                    }

                    {
                        (
                            <Drawer
                                anchor="bottom"
                                open={ openDrawer }
                                sx={{
                                    zIndex: 100
                                }}
                                PaperProps={{
                                    sx:{
                                        pt: 10,
                                        pb: 5,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }
                                }}
                            >
                                <ZonePhonePreview
                                    relative
                                />
                            </Drawer>
                        )
                    }
                </Container>
            </Paper>
        </>
    )
};

export default withTheme( ZoneForm );

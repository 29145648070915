import * as React from 'react';
import { Container, Paper, useTheme, Typography, FormGroup, FormControlLabel } from '@mui/material';

import { PixabaySelector, usePixabaySelector } from '../../components/PixabaySelector';

import FormNavigationButtons from '../../components/FormNavigationButtons';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { updateZone } from '../../actions/zones';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { updateRecord } from '../../actions/updateRecord';
import { fetchFile } from '../../actions/fetchFile';

import CustomThemeCreator from '../../components/CustomThemeCreator';
import { useFormNavigationButtons } from '../../components/FormNavigationButtons';
import { updateTheme } from '../../actions/themes';
import { transition } from '../../constants/transitions';
import { resizeImage } from '../../actions/resizeImage';
import { fileToDataUri } from '../UploadFile';
import StyledDarkLightToggleSwitch from '../../styled/StyledDarkLightToggleSwitch';

const ThemeSection = ({ prev, next, fullForm, setStep }:{ prev?:number, next?:number, fullForm?:boolean, setStep:any }) => {
    
    const dispatch = useDispatch();

    const state = useSelector( (state:any) => state );
    const theme = useTheme();


    const ref = React.useRef<any>(null);

    const { buttonSaveProperties, setButtonSaveProperties } = useFormNavigationButtons();

    const { arrayRef, pixabayResults, handleSearch, handleSearchInputChange, searchOptions, setSearchOptions, loadingResults } = usePixabaySelector();
    const [ themeMode, setThemeMode ] = React.useState<string>( 'create' );

    const [ currentThemeState, setCurrentThemeState ] = React.useState<any>( null );

    React.useEffect(() => {        

        setCurrentThemeState( state.theme );

        if( state?.theme?.backgroundImageUrl ) {
            setButtonSaveProperties((prev:any) => ({
                ...prev,
                text: 'Continuar',
                isVisible: true,
            }));
        }
        

    },[ ]);

    React.useEffect(() => {
        //Detects if the state theme changes and shows the button
        if( state.theme !== currentThemeState && themeMode !== 'myThemes' ) {
            setButtonSaveProperties((prev:any) => ({
                ...prev,
            }))
        }
    },[ state.theme ]);

    React.useEffect(() => {
        if( themeMode === 'myThemes' ) {
            setButtonSaveProperties((prev:any) => ({
                ...prev,
                isVisible: false,
            }))
        }
    },[ themeMode ]);

    const handleChangeMode = (
      event: React.MouseEvent<HTMLElement>,
      newMode: string,
    ) => {
        setThemeMode( newMode );
    };

    const submitTheme = async () => {
        const theme = state.theme;
        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Guardando',
            isDisabled: true,
            isLoading: true
        }));

        let image:any = null;
        const formData = new FormData();

        if( themeMode === 'search' ) {
            //fetch image from pixabay
            const imageDownloaded:any = await fetchFile( state.theme.backgroundImageUrl );
            formData.append( 'file', imageDownloaded );
            formData.append( 'folderName', `/themes` );

            const resized:any = await resizeImage( imageDownloaded ); //Starts resizing image
         
            formData.append( 'file', resized );
            const dataImage = await fileToDataUri( resized );

            image = {
                dataImage, 
                type: imageDownloaded.type,
                name: imageDownloaded.name
            }; 
        } else {
            image = await ref.current.uploadImageToServer(); //Upload custom image to server
        }

        dispatch( updateTheme({
            ...theme,
            dataImage: image
        }) );

        setButtonSaveProperties((prev:any) => ({
            ...prev,
            text: 'Guardar',
            isDisabled: false,
            isLoading: false
        }));

        setStep( 2 );

    }

    return(
        <>
            <Paper
                sx={{
                    py: 2,
                    mt: 4,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 4,
                    position:'relative',                
                }}
                elevation={ 1 }
            >
                <Container maxWidth="md">
                    <Box
                        sx={{
                            mb: 4
                        }}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={ themeMode }
                            exclusive
                            onChange={ handleChangeMode }
                            fullWidth
                        >
                            
                            <ToggleButton
                                sx={{
                                    textTransform: 'none'
                                }}
                                size="small"
                                value="create"
                            >
                                Subir imagen
                            </ToggleButton>
                            <ToggleButton
                                sx={{
                                    textTransform: 'none'
                                }}
                                size="small"
                                value="search"
                            >
                                Buscar imagen
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    {
                        themeMode === 'search' && (
                            <Box>
                                <PixabaySelector
                                    setButtonSaveProperties={ setButtonSaveProperties }
                                    handleSearchInputChange={ handleSearchInputChange }
                                    handleSearch={ handleSearch }
                                    pixabayResults={ pixabayResults }
                                    arrayRef={ arrayRef }
                                    setSearchOptions={ setSearchOptions }
                                    searchOptions={ searchOptions }
                                    loadingResults={ loadingResults }
                                    fullForm={ fullForm }
                                    isPremium
                                />
                                <Box>
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        color="text.secondary"
                                    > 
                                        </Typography>
                                </Box>
                            </Box>
                        ) 
                    }
                    {
                        themeMode === 'create' && (
                            <>
                                <CustomThemeCreator setButtonSaveProperties={ setButtonSaveProperties } ref={ref}/>
                            </>

                        )
                    }

                    <Box sx={{ mt: 4 }}>
                        <FormGroup>
                        <FormControlLabel control={<StyledDarkLightToggleSwitch
                            defaultChecked={ true }
                            value={ state?.theme?.mode === 'dark' }
                            onChange={ (e) => dispatch(updateTheme({
                                ...state.theme,
                                mode: e.target.checked ? 'dark' : 'light'
                            }))}
                            
                        />} label={ state?.theme?.mode === 'dark' ? 'Modo oscuro activado' : 'Modo claro activado' } />
                        </FormGroup>
                        
                    </Box>
                    <Box sx={{
                        mt: 4,
                        mb: fullForm ? 10 : 4,
                    }}>
                        {
                            
                             <FormNavigationButtons
                                next={ submitTheme }
                                buttonSaveProperties={ buttonSaveProperties }
                            />
                
                        }

                    </Box>
                </Container>
                
            </Paper>
        </>
        
    )
}

export default ThemeSection;
import * as React from 'react';
import Box from '@mui/material/Box';
import BannerPreview from './BannerPreview';
import { useSelector } from 'react-redux';
import MainContentPreview from './MainContentPreview';

const ZoneComponent = () => {
    const { zone, theme } = useSelector( (state:any) => state );

    return (
        <>
        
         <Box sx={{
             position: 'absolute',
         }} >
             <>
                <BannerPreview
                    data={ zone }
                    backgroundImageUrl={ theme?.backgroundImageUrl }
                />
                <MainContentPreview/>
                
             </>
         </Box>
        </>

    )
}

export default ZoneComponent;
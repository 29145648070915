import * as React from 'react'
import { Box } from '@mui/system';
import { usePalette } from 'react-palette';
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";


export const ColorPaletteImage = React.forwardRef((props:any,ref:any) => {
        const { data, loading } = usePalette(props.src);

        const colorStyle = {
            width:  25,
            height: props?.modal ? 50 : 25,
            borderRadius: props?.modal ? 4 : '50%',
            border: props?.modal ? 'none' : '1px solid white',
            position: props?.modal ? 'relative' : 'absolute'
        }

        React.useEffect(() => {
            if( !props?.setCurrentPalette ) return;

            if(data && !loading) {
                props.setCurrentPalette({
                    vibrant: data.vibrant,
                    lightVibrant: data.lightVibrant,
                    darkVibrant: data.darkVibrant,
                    muted: data.muted,
                    lightMuted: data.lightMuted,
                    darkMuted: data.darkMuted
                });
            }
        },[data]);

        React.useImperativeHandle(ref,() => ({
            updatePalette: () => {
                if( !props?.setCurrentPalette ) return;
                props?.setCurrentPalette({
                    vibrant: data.vibrant,
                    lightVibrant: data.lightVibrant,
                    darkVibrant: data.darkVibrant,
                    muted: data.muted,
                    lightMuted: data.lightMuted,
                    darkMuted: data.darkMuted
                });
            }})
        );

        const interactiveColor = ( color ) => (
            <Stack
                sx={{
                    cursor: 'pointer'
                }}
                alignItems="center"
            >
                <Button
                    onClick={() => {
                        navigator.clipboard.writeText( color.toUpperCase() );
                        props?.setSelectedColor( color.toUpperCase() );
                    }}
                    sx={{
                        minWidth: 10,
                        height: 50,
                        width: 25,
                        borderRadius: 4,
                        backgroundColor: color
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 10,
                        color: 'rgba( 0,0,0,0.8) '
                    }}
                >
                    { color }
                </Typography>
            </Stack>
        )

        const normalColor = ( color, left ) => (
            <Box sx={{
                ...colorStyle,
                backgroundColor: color,
                left,
            }}/>
        )
        return (
            <Box sx={{
                display: props?.centered && 'flex',
                justifyContent: props?.centered && 'center',
                position: 'relative',
                width: props?.modal  ? '100%' : 132,
                height:  props?.modal  ? 'auto' : 52,
            }}>
                {!loading &&
                    <Box sx={{padding: '1rem 0', position: 'relative' }} display="flex" justifyContent="space-evenly">
                        {
                            props?.modal ? interactiveColor( data.vibrant ) : normalColor( data.vibrant, 0 )
                        }
                        {
                            props?.modal ? interactiveColor( data.lightVibrant ) : normalColor( data.lightVibrant, 20 )
                        }
                        {
                            props?.modal ? interactiveColor( data.muted ) : normalColor( data.muted, 40 )
                        }
                        {
                            props?.modal ? interactiveColor( data.lightMuted ) : normalColor( data.lightMuted, 60 )
                        }
                        {
                            props?.modal ? interactiveColor( data.darkVibrant ) : normalColor( data.darkVibrant, 80 )
                        }
                        {
                            props?.modal ? interactiveColor( data.darkMuted ) : normalColor( data.darkMuted, 100 )
                        }
                    </Box>}
            </Box>
        )
    }
)

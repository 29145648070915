import * as React from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ThemeCard from './ThemeCard';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Search from '@mui/icons-material/Search';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import StyledButton from '../styled/StyledButton';
import CircularProgressComponent from './CircularProgressComponent';

export type SearchOptions = {
    query?: String,
    lang: String,
    imageType: String | 'photo' | 'illustration' | undefined,
    colors: any | undefined,
    category: String | 'backgrounds' | 'fashion' | 'nature' | 'science' | 'education' | 'feelings' | 'health' | 'people' | 'religion' | 'places' | 'animals' | 'industry' | 'computer' | 'food' | 'sports' | 'transportation' | 'travel' | 'buildings' | 'business' | 'music' | undefined,
    perPage: number,
    page: number,
}
const ___DEFAULT_QUERY_SEARCH__ = 'verano';

export const usePixabaySelector = () => {
    const [ pixabayResults, setPixabayResults ] = React.useState([]);
    const [ arrayRef, setArrayRef ] = React.useState([]);
    const [ querySearch, setQuerySearch ] = React.useState<any>( null );
    const [ loadingResults, setLoadingResults ] = React.useState<boolean>( false );
    const [ searchOptions, setSearchOptions ] = React.useState<SearchOptions>({
        query: ___DEFAULT_QUERY_SEARCH__,
        lang: 'es',
        imageType: undefined,
        colors: '',
        category: undefined,
        perPage: 6,
        page: 1
    });

    const pixabay_api = "https://pixabay.com/api/?key=25105059-4d7ff3f9a607aabea05e93997";

    React.useEffect(() => {
        retrievePixabayImages();
    }, [ searchOptions ]);

    const handleSearchInputChange = ( e:any ) => {
        setQuerySearch( e.target.value );
    }

    const handleSearch = () => {
        setSearchOptions({
            ...searchOptions,
            query: querySearch
        });
    }

    const retrievePixabayImages = async () => {
        setLoadingResults( true );
        setPixabayResults([]);

        const fullPath = `${ pixabay_api }${searchOptions.lang && '&lang=' + searchOptions.lang}${searchOptions.query && '&q=' + searchOptions.query}${searchOptions.colors !== undefined && searchOptions.colors !== false && '&colors=' + searchOptions.colors}${searchOptions.category !== undefined && '&category=' + searchOptions.category}${searchOptions.imageType !== undefined && '&image_type=' + searchOptions.imageType}${searchOptions.perPage && '&per_page=' + searchOptions.perPage}${searchOptions.page && '&page=' + searchOptions.page}`

        try{
            const result = await axios.get(fullPath);

            if(result) {
                const arr:any = [];
                result.data.hits.map((v:any,i:any) => (
                    arr[i] = React.createRef()
                ));

                setArrayRef(arr)
                setPixabayResults(result.data.hits);
                setLoadingResults( false );
            }
        }catch(e) {
            console.log( e );
        }

    }

    return {
        searchOptions,
        loadingResults,
        handleSearch,
        setSearchOptions,
        handleSearchInputChange,
        pixabayResults,
        arrayRef
    }
}



export const PixabaySelector = ({
                                    searchOptions,
                                    loadingResults,
                                    pixabayResults,
                                    arrayRef,
                                    handleSearchInputChange,
                                    handleSearch,
                                    setSearchOptions,
                                    isPremium,
                                    fullForm,
                                    setButtonSaveProperties,
                                }:{
    setButtonSaveProperties?:any,
    searchOptions:any,
    loadingResults: boolean,
    setSearchOptions: any,
    pixabayResults:any,
    arrayRef:any,
    handleSearchInputChange?:any,
    handleSearch?:any,
    isPremium?: boolean,
    fullForm?: boolean
}) => {

    return(
        <>
            <Grid container>
                <Grid xs={ 12 } item>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Busca entre miles de temas..."
                        onChange={ handleSearchInputChange }
                        placeholder={ ___DEFAULT_QUERY_SEARCH__ }
                        onKeyDown={ (e:any) => {
                            if(e.keyCode == 13){
                                handleSearch();
                            }
                        } }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={ handleSearch }
                                    >
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {
                loadingResults ? <CircularProgressComponent/> : (
                    <Grid sx={{ mt: 2 }} spacing={ 2 } container>
                        {
                            pixabayResults.map((value:any, index:number) => {

                                return(
                                    <Grid item xs={6}>
                                        <ThemeCard
                                            setButtonSaveProperties={ setButtonSaveProperties }
                                            isPremium={ (( index > 3 ) && isPremium ) }
                                            arrayRef={ arrayRef }
                                            largeImageURL={ value.largeImageURL }
                                            urlImage={ value.previewURL }
                                            darkMode={ false }
                                            index={ index }
                                        />
                                    </Grid>
                                )})
                        }
                    </Grid>
                )
            }
            <Typography sx={{ mt: 2 }} variant="caption" color="text.secondary">
                Selecciona una imagen de fondo para continuar, podrás elegir más al registrarte.
            </Typography>

            {
                fullForm && (
                    <Stack sx={{ mt: 3, mb: 4 }} justifyContent="center" spacing={ 2 } direction="row">
                        {
                            ( searchOptions.page > 1 ) && (
                                <StyledButton
                                    onClick={ () => setSearchOptions({
                                        ...searchOptions,
                                        page: searchOptions.page - 1
                                    }) }
                                    color="secondary"
                                    variant="contained"
                                    size="medium"
                                    startIcon={ <ChevronLeft/> }
                                >
                                    Página anterior
                                </StyledButton>
                            )
                        }

                        <StyledButton
                            onClick={ () => setSearchOptions({
                                ...searchOptions,
                                page: searchOptions.page + 1
                            }) }
                            variant="contained"
                            size="medium"
                            color="secondary"
                            endIcon={ <ChevronRight/> }
                        >
                            Página siguiente
                        </StyledButton>
                    </Stack>
                )
            }
        </>

    )
}

import axios from 'axios';
import { actionTypes } from '../actionTypes/actionTypes';


const { GATSBY_API_HOST } = process.env;

export const startValidateJWT = ( jwt:string ) => {

    return async ( dispatch:any ) => {

        try{
            const { data } = await axios.get(
                `${ GATSBY_API_HOST }auth`,
                {
                    headers: {'x-token': jwt}
                }
            );

            if(data.user) {
                return data.user;
            }else{
                //dispatch( logout );
                return false;
            }
        }catch(e){
            console.log(e);
        }
    
    }
}

export const startLogin = ( email:string, password:string ) => {

    const payload = {
        email,
        password
    }

    return async (  ) => {

        try{
            const { data } = await axios.post(
                `${ GATSBY_API_HOST }auth/login`,
                payload
            );
    
            return data.token
        }catch(e:any){
            return e.response.data
        }
        
    }
}

export const startRegister = ( name:string, email:string, password:string, role:string = "USER" ) => {
    const payload = {
        name, 
        email,
        password,
        role
    }

    return async ( dispatch:any ) => {
        await axios.post(
            `${ GATSBY_API_HOST }users`,
            payload
        );

        dispatch( startLogin( email, password ) );
    }
}

export const login = ( isNewUser:boolean, name:string, uid:string, token:string, zonesCounter:string, subscription:string, img?:string ) => ({
    type: actionTypes.login,
    payload: {
        isNewUser,
        name,
        uid,
        token,
        zonesCounter,
        subscription,
        img
    }
});

export const logout = () => ({
    type: actionTypes.logout,
})
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useTheme from '@mui/material/styles/useTheme';
import StyledButton from "../styled/StyledButton";
import ZonePublishedAnimation from './ZonePublishedAnimation';
import { downloadQr } from '../helpers/downloadQr';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
};

type ModalPremiumType = {
    handleModal: any;
    openModal: boolean;
    zoneUrl: string;
}

export const useModalPublished = (  ) => {
    const [ openModal, setOpenModal ] = React.useState(false);
    const [ modalTitle, setModalTitle ] = React.useState<string>(' ');
    const handleModal = () => setOpenModal(!openModal);

    return {
        openModal,
        handleModal,
        modalTitle,
        setModalTitle,
    }
}

const ModalZonePublished = ({ handleModal, openModal, zoneUrl }:ModalPremiumType) => {
    const theme = useTheme();
    const QRCodeId:string = "QRCode";

    return(
        <Modal
            sx={{
                overflow: 'scroll'
            }}
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ZonePublishedAnimation zoneUrl={ zoneUrl } />
                <Box sx={{ p: 3, pb: 2 }}>
                    <Typography
                        color="secondary"
                        align="center"
                        id="modal-modal-title"
                        variant="h6"
                        sx={{
                            mb: 2
                        }}
                    >
                        ¿Cómo lo comparto?
                    </Typography>
                    <Typography
                        variant="caption"
                        color="secondary"
                    >
                        Descargando tu QR
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderRadius: 2,
                            py: 2,
                            border: `solid 1px ${ theme.palette.secondary.main }`
                        }}
                    >
                        <StyledButton
                            sx={{
                                borderRadius: 8,
                                mt: 2,
                            }}
                            variant="contained"
                            size='small'
                            onClick={ () => downloadQr( QRCodeId, 'qr_de_mi_zone' ) }
                        >
                            Descargar tu QR
                        </StyledButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalZonePublished;
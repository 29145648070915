import * as React from 'react';
import Box from '@mui/material/Box';
import ColorSelector from './ColorSelector';
import { UploadFile, useUploader } from './UploadFile';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTheme } from '../actions/themes';

const CustomThemeCreator = forwardRef((props:any,ref:any) => {
    const { theme, zone } = useSelector( ( state:any ) => state );
    const dispatch = useDispatch();
    const { setDataUri, dataUri, onChange, handleDelete, uploadToServer } = useUploader();
    
    const [ imagesToColorsArray, setImagesToColorsArray ] = useState<any>([]);

    useEffect(() => {
        if( theme?.backgroundImageUrl ) {
            setDataUri( theme?.backgroundImageUrl )
        }
    },[ ])
    useEffect(() => {

        dispatch( updateTheme({
            ...theme,
            backgroundImageUrl: dataUri ? dataUri : theme?.backgroundImageUrl
        }) );

    },[dataUri]);

    useImperativeHandle(ref,() => ({
        uploadImageToServer: () => {
            return uploadToServer( `/themes` )
        }})
    );

    useEffect(() => {
        if( dataUri ) {
            setImagesToColorsArray((prev:any) => [
                ...prev,
                {
                    title: '¡Obtuvimos estos colores de tu imagen!',
                    image: dataUri
                }
            ])
        }
    },[ dataUri ])

    return(
        <Box>
            <UploadFile
                dataUri={ dataUri } 
                onChange={ onChange }
                handleDelete={ handleDelete }
                title="¡Sube una imagen y mira el resultado!"
                height={ 200 }
            />
            <Box
                sx={{
                    mt: 2
                }}
            >
                <ColorSelector setButtonSaveProperties={ props.setButtonSaveProperties } imagesToColorsArray={ imagesToColorsArray } zone={ zone.uid } centered/>
            </Box>
        </Box>
    )
})

export default CustomThemeCreator;
import * as React from 'react';
import { LinearProgress, Typography, Grid } from '@mui/material';
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
import { motion } from 'framer-motion';
import { transition } from '../constants/transitions';

const StepperComponent = ( { actualStep, totalSteps, titleStep }:{ actualStep:number, totalSteps:number, titleStep?:string } ) => {
    return(
        <Grid sx={{ alignItems: 'center' }} container>
            <Grid xs={ 9 } item>
                <Typography variant='body2'>
                    Paso { actualStep }
                </Typography>
                <motion.div
                    initial={{
                        x: 20,
                        opacity: 0

                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                        transition: { ...transition }
                    }}
                >
                    <Typography variant='h6'>
                            { titleStep }
                        </Typography>
                </motion.div>
              
            </Grid>
            <Grid sx={{ justifyContent: 'right', display: 'flex' }} xs={ 3 } item>
                <Box sx={{ position: 'relative' }}>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                            float: 'right'
                        }}
                        size={60}
                        thickness={4}
                        value={100}
                    />
                    <CircularProgress
                        variant="determinate"
                        value={ (actualStep / totalSteps ) * 100 }
                        color="primary"
                        disableShrink
                        sx={{
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                        }}
                        size={60}
                        thickness={4}
                    />
                    <Box
                        sx={{
                        top: -5,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                    >
                        <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                        > { `${actualStep} de ${ totalSteps }` } </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default StepperComponent;
import * as React from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Layout from "../components/layout"
import Seo from "../components/seo"
import withTheme from "../components/WithTheme";
import ZoneForm from "../components/zoneForm/ZoneForm";
import { motion } from 'framer-motion';
import { transition } from '../constants/transitions';
import useTheme from '@mui/material/styles/useTheme';
import StyledButton from '../styled/StyledButton';
import { StyledIframe } from '../styled/StyledIframe';
import Box from '@mui/material/Box';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import { TypingAnimation } from "../components/common/TypingAnimation";
// @ts-ignore
import IllustrationDay from '../images/illustration-light.svg';
// @ts-ignore
import IllustrationNight from '../images/illustration-dark.svg';

const IndexPage = () => {
    const theme = useTheme();
    const mediaQuery = useMediaQuery(theme.breakpoints.up('md'));
    const [ currentUrl, setCurrentUrl ] = React.useState<string | null>( null );
    const urls = [
        'nucleodev',
        'juridicorn',
        'wowkatze',
        'emmanuel',
        'aratalatam',
        'drapshop',
        'amaimomo',
        'rinose.xalapa',
        'adrianguerra.art',
        'genesis12203rm',
        'bingsun'
    ]

    React.useEffect(() => {
        setCurrentUrl(
            urls[ Math.floor(Math.random()*urls.length) ]
        )
    },[]);

    return(
        <Layout>
            <Seo title="Inicio" />
            <Paper sx={{
                backgroundImage: `url(${ theme.palette.mode === 'dark' ? IllustrationNight : IllustrationDay })`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                py: mediaQuery ? 18 : 4
            }}>
                <Container sx={{ pt: 10 }}>
                    <Grid container>
                        <Grid item xs={ 12 }  md={ 6 }>
                            <Stack>
                                <motion.div
                                    initial={{
                                        y: 40,
                                        opacity: 0,
                                    }}
                                    animate={{
                                        y: 0,
                                        opacity: 1,
                                        transition: { ...transition }
                                    }}
                                >
                                    <Typography sx={{ mb: 0, mt: mediaQuery ? 10 : 0, fontWeight: 700 }} align={ mediaQuery ? 'left' : 'center' } variant="h3">
                                        Todos tus links en un Zone.
                                    </Typography>
                                    <Box sx={{ width: '20em', display: 'flex', justifyContent: mediaQuery ? 'left' : 'center' }}>
                                        <TypingAnimation/>
                                    </Box>
                                </motion.div>
                                <motion.div
                                    initial={{
                                        y: 0,
                                        opacity: 0,
                                    }}
                                    animate={{
                                        y: 20,
                                        opacity: 1,
                                        transition: { ...transition }
                                    }}
                                >
                                    <Typography sx={{ mb: 0, fontWeight: 300 }} align={ mediaQuery ? 'left' : 'center' } variant="body1">
                                        Crea, personaliza y comparte tu perfil en cuestión de segundos.
                                    </Typography>
                                    <Box sx={{ justifyContent: mediaQuery ? 'left' : 'center', display: 'flex' }}>
                                        <motion.div
                                            animate={{ scale: [1, 1.05, 1] }}
                                            transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
                                        >
                                            <AnchorLink to="/#creator">
                                                <StyledButton
                                                    sx={{ mt: 4 }}
                                                    endIcon={ <ArrowRightAlt/> }
                                                    variant="contained"
                                                    size="large"
                                                    color="secondary"
                                                >
                                                    ¡Comienza gratis!
                                                </StyledButton>
                                            </AnchorLink>
                                        </motion.div>
                                    </Box>
                                </motion.div>
                            </Stack>
                        </Grid>
                        <Grid item xs={ 12 } md={ 6 }>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    height: 550,
                                    x: 20
                                }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    left: 0,
                                    right: 0,
                                    transition: { ...transition, delay: 1.4 }
                                }}
                                style={{
                                    zIndex: 1,
                                    marginTop: mediaQuery ? 0 : 80,
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {
                                    currentUrl && (
                                        <StyledIframe
                                            src={ `https://ohmy.zone/${ currentUrl }` }
                                        />
                                    )
                                }
                            </motion.div>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            <Grid sx={{ py: 4 }} container>
                <Grid item xs={12}>
                    <ZoneForm/>
                </Grid>
            </Grid>
        </Layout>
    )}

export default withTheme( IndexPage );

import * as React from 'react';
import GoogleIcon from '../icons/GoogleIcon';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useGoogleLogin } from 'react-google-login';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { login } from '../../actions/auth';

export const useGoogleButton = () => {
    const [ loadingGoogle, setLoadingGoogle ] = useState<boolean>( false );
    const dispatch = useAppDispatch();

    const onSuccessGoogleAuth = async (response:any) => {
        try{
            const { data } = await axios.post(
                `${  process.env.GATSBY_API_HOST }auth/google`,
                response.tokenObj
            );
    
            if( data?.token ) {
                dispatch( login(
                    data.isNewUser,
                    data.user.name,
                    data.user.uid,
                    data.token,
                    data.user?.subscription,
                    data.picture,
                ) );
            }

            setLoadingGoogle( false );

        }catch(e:any){
            return e?.response?.data
        }
    }

    const { signIn, loaded } = useGoogleLogin({
        onSuccess: onSuccessGoogleAuth,
        onFailure: (success:any) => {
            alert('Ocurrió un error al iniciar sesión con Google.');
            setLoadingGoogle( false );
        },
        clientId: process.env.GATSBY_APP_GOOGLE_CLIENT,
    });

    return({
        signIn,
        loadingGoogle,
        setLoadingGoogle
    })

}

export const GoogleButton = ({signIn, loadingGoogle, setLoadingGoogle }:{signIn:any, loadingGoogle:boolean, setLoadingGoogle:any }) => {
    
    return(
        <Button
            id="google-sign-up-button"
            disabled={ loadingGoogle }
            sx={{
                width: '100%',
                backgroundColor: 'white',
                color: '#4664F6',
                padding: '14px 0',
                borderRadius: 3.5,
                textTransform: 'none'
            }}
            onClick={ () => {
                setLoadingGoogle( true );
                signIn();
            } }
            startIcon={ loadingGoogle ? <CircularProgress size={ 12 }/> : <GoogleIcon/> }
        >
            Accede con Google
        </Button>
    )
}